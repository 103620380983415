<template>
  <v-tooltip
    v-model="isTooltipActive"
    :location="location"
    :content-class="['j-tooltip', contentClass]"
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
  >
    <template #activator="{ isActive, props }">
      <slot
        name="activator"
        v-bind="{ isActive, props }"
      />
    </template>
    <slot />
  </v-tooltip>
</template>
<script setup lang="ts">
import { DEVICE } from '~/constants/general';
import type { Anchor } from '~/types/components/General/Tooltip';

defineProps({
  location: {
    type: String as PropType<Anchor>,
    default: 'bottom',
  },
  contentClass: {
    type: String,
    default: '',
  },
});

const isTooltipActive = ref(false);
const isDesktop = computed(() => useDeviceType() === DEVICE.desktop);
/**
 * Computed property that indicates whether the tooltip should open on click.
 * It returns true if the device is not desktop and the tooltip is not active.
 */
const openOnClick = computed(
  () => !isDesktop.value && !isTooltipActive.value,
);
/**
 * Computed property that indicates whether the tooltip should open on hover.
 * It returns true if the device is desktop or the tooltip is currently active.
 */
const openOnHover = computed(
  () => isDesktop.value || isTooltipActive.value,
);
</script>
<style lang="scss">
.j-tooltip {
  background-color: $j-color-bg-block-light !important;
  color: $j-color-text-regular !important;
  font-size: $j-form-label-font-size !important;
  padding: 8px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  pointer-events: initial !important;
  &.j-tooltip--green {
    background-color: $j-color-green !important;
    color: $j-color-tooltip-content !important;
  }
  &.j-tooltip--error {
    background-color: $j-color-error !important;
    color: $j-color-tooltip-content !important;
  }
  &.j-tooltip--orange {
    background-color: $j-color-orange !important;
    color: $j-color-tooltip-content !important;
  }
  &.j-tooltip--secondary {
    background-color: $j-color-text-secondary !important;
    color: $j-color-tooltip-content !important;
  }
  &.j-tooltip--cash-promo {
    .j-tooltip__content--card-regular {
      min-width: 188px;
    }
    .j-tooltip__content--card-small {
      min-width: 260px;
    }
  }
  &.j-tooltip--table {
    left: 14px !important;
  }
}
.j-tooltip-icon {
  .v-icon__svg path {
    fill: $j-color-text-secondary;
  }
}
</style>