<template>
  <v-table :class="['j-table', { 'j-table--no-adaptive': disableAdaptivity }]">
    <thead
      :class="[
        'j-table__head',
        { 'j-table__head--no-adaptive': disableAdaptivity },
      ]"
    >
      <tr class="j-table__row">
        <th
          v-for="(header, index) in componentProps.headers"
          :key="`th_${index}`"
          :class="[
            'j-table__cell',
            'j-table__cell--head',
            'font-weight-regular',
            'py-2',
            tableHeaderPaddingX,
          ]"
        >
          <general-text
            :dictionary-key="header.dictionaryKey"
            :class="[
              'j-text--fs-12 j-text--white',
              { 'text-center': disableAdaptivity && isXsDisplay },
            ]"
          />
        </th>
      </tr>
    </thead>
    <tbody class="j-table__body">
      <tr
        v-for="(item, rowIndex) in componentProps.items"
        :key="`tr_${rowIndex}`"
        :class="[
          'j-table__row j-table__row--body',
          {
            'j-table__row--is-highlighted': item.isHighlight,
            'j-table__row--is-highlighted-mobile':
              item.isHighlight && disableAdaptivity,
            'j-table__row--no-adaptive': disableAdaptivity,
          },
        ]"
      >
        <td
          v-for="(header, colIndex) in componentProps.headers"
          :key="`td_${rowIndex}${colIndex}`"
          :data-label="$t(header.dictionaryKey)"
          :class="[
            'j-table__cell j-table__cell--body text-start',
            {
              'j-table__cell--highlight-left-border':
                item.isHighlight && colIndex === 0,
              'j-table__cell--highlight-left-border-mobile':
                disableAdaptivity && item.isHighlight && colIndex === 0,
              'j-table__cell--no-adaptive': disableAdaptivity,
            },
          ]"
        >
          <div
            :class="[
              {
                'd-flex align-center': isCellHasActionElement(
                  item[header.fieldName],
                  'tooltip',
                ),
              },
              { 'justify-center': disableAdaptivity && isXsDisplay },
            ]"
          >
            <general-text
              :dictionary-key="
                String(getCellProperty(item[header.fieldName], 'value'))
              "
              :class="[
                'j-text--white',
                `j-text--${getCellProperty(
                  item[header.fieldName],
                  'contentClass',
                )}`,
                { 'text-center': disableAdaptivity && isXsDisplay },
              ]"
            />
            <general-tooltip
              v-if="isCellHasActionElement(item[header.fieldName], 'tooltip')"
              :content-class="getTooltipClass(item[header.fieldName])"
            >
              <template #activator="{ props }">
                <v-icon
                  v-bind="props"
                  :class="[
                    'ml-1',
                    'j-table__tooltip-icon',
                    `j-table__tooltip-icon--${getCellProperty(
                      item[header.fieldName],
                      'contentClass',
                    )}`,
                  ]"
                  icon="custom:tooltipIconActive"
                />
              </template>
              {{ getCellProperty(item[header.fieldName], 'tooltip') }}
            </general-tooltip>
            <v-btn
              v-if="isCellHasActionElement(item[header.fieldName], 'button')"
              variant="text"
              size="small"
              :ripple="false"
              height="16px"
              block
              min-width="max-content"
              class="j-btn j-btn--no-hover j-btn--no-active j-btn--no-border text-none text-body-1 pa-0 mt-4"
              @click="$emit('action', item)"
            >
              <general-text
                :dictionary-key="
                  String(getCellProperty(item[header.fieldName], 'buttonValue'))
                "
                :class="[
                  'font-weight-regular',
                  `j-text--${getCellProperty(
                    item[header.fieldName],
                    'buttonClass',
                  )}`,
                ]"
              />
            </v-btn>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">
import type {
  TableColumnHeader,
  TableCellItem,
  TableCellProps,
  TableCellActionElements,
} from '~/types/components/General/Table';

const componentProps = defineProps({
  headers: {
    type: Array as PropType<TableColumnHeader[]>,
    default: () => [],
  },
  items: {
    type: Array as PropType<{ [key: string]: TableCellItem }[]>,
    default: () => [],
  },
  disableAdaptivity: {
    type: Boolean,
    default: false,
  },
});
const isCellHasActionElement = (
  cellData: TableCellItem,
  actionElement: TableCellActionElements,
): boolean => {
  return typeof cellData === 'object' && cellData[actionElement] !== undefined;
};
const getCellProperty = (
  cellData: TableCellItem,
  property: TableCellProps,
): string | number | undefined => {
  if (typeof cellData === 'object') {
    switch (property) {
      case 'value':
        return cellData.value;
      case 'contentClass':
        return cellData.contentClass ?? '';
      case 'tooltip':
        return cellData.tooltip;
      case 'buttonValue':
        return cellData.button?.value ?? '';
      case 'buttonClass':
        return cellData.button?.contentClass ?? '';
      default:
        return '';
    }
  } else {
    return property === 'value'
      ? cellData
      : '';
  }
};
const getTooltipClass = (cellData: TableCellItem) => {
  return `j-tooltip--${getCellProperty(cellData, 'contentClass')} ${isXsDisplay?.value
    ? 'j-tooltip--table'
    : ''}`;
};
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const tableHeaderPaddingX = computed(() =>
  componentProps.disableAdaptivity && isXsDisplay?.value
    ? 'px-1'
    : 'px-6',
);

defineEmits(['action']);
</script>

<style scoped lang="scss">
.j-table {
  background-color: transparent;
  :deep(table) {
    border-spacing: 0px 8px;
    line-height: normal;
  }
}
.j-table__head {
  display: none;
  background-color: $j-color-bg-block-dark;
}
.j-table__cell--head {
  height: 32px !important;
  border-bottom: none !important;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}
.j-table__row--body {
  background-color: $j-color-btn-regular;
}
.j-table__cell--body {
  display: block;
  height: auto !important;
  border-bottom: none !important;
  padding: 8px 16px !important;
  &:first-child {
    padding-top: 16px !important;
  }
  &:last-child {
    padding-bottom: 16px !important;
  }
  &[data-label]::before {
    content: attr(data-label);
    display: block;
    font-size: 12px;
    opacity: 0.3;
    margin-bottom: 4px;
  }
}

.j-table.j-table--no-adaptive {
  :deep(table) {
    border-spacing: 0px 4px;
  }
}
.j-table__head--no-adaptive {
  display: table-header-group;
}
.j-table__row--no-adaptive {
  background-color: $j-color-btn-regular;
}
.j-table__row--is-highlighted-mobile {
  background: $j-gradient-table;
}
.j-table__cell--no-adaptive {
  display: table-cell;
  height: 40px !important;
  padding: 12px 4px !important;
  &:first-child {
    padding-top: 12px !important;
  }
  &:last-child {
    padding-bottom: 12px !important;
  }
  &[data-label]::before {
    content: none;
  }
}
.j-table__cell--highlight-left-border-mobile {
  border-left: 1px solid $j-color-btn-border;
}

@media screen and (min-width: 600px) {
  .j-table {
    :deep(table) {
      border-spacing: 0px 4px;
      table-layout: fixed;
    }
  }
  .j-table__head {
    display: table-header-group;
  }
  .j-table__row--is-highlighted {
    background: $j-gradient-table;
  }
  .j-table__cell--body {
    display: table-cell;
    height: 40px !important;
    padding: 12px 24px !important;
    word-break: break-word;
    &:first-child {
      padding-top: 12px !important;
    }
    &:last-child {
      padding-bottom: 12px !important;
    }
    &[data-label]::before {
      content: none;
    }
  }
  .j-table__cell--highlight-left-border {
    border-left: 1px solid $j-color-btn-border;
  }
  .j-table__cell--no-adaptive {
    padding: 12px 24px !important;
  }
}
.j-table__tooltip-icon {
  :deep(path) {
    fill: inherit;
  }
  &.j-table__tooltip-icon--green {
    color: $j-color-green !important;
  }
  &.j-table__tooltip-icon--error {
    color: $j-color-error !important;
  }
  &.j-table__tooltip-icon--orange {
    color: $j-color-orange !important;
  }
  &.j-table__tooltip-icon--secondary {
    color: $j-color-text-secondary !important;
  }
}
</style>
