<template>
  <div class="j-page-apb mb-16 pb-4">
    <div class="j-page-apb__section mt-lg-16 pt-lg-6 mt-3 text-center">
      <general-text
        :text-types="TEXT_TYPES.pageTitle"
        dictionary-key="apb_title"
        class="j-text--white"
      />
    </div>
    <div class="j-page-apb__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="apb_registration_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(registrText, index) of apbRegistrationText"
      :key="`registr_text_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="registrText" />
    </div>
    <div class="j-page-apb__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="apb_confirmation_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(confirmText, index) of apbConfirmationText"
      :key="`confirm_text_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="confirmText" />
    </div>
    <div class="j-page-apb__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="apb_security_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(securityText, index) of apbSecurityText"
      :key="`security_text_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="securityText" />
    </div>
    <div class="j-page-apb__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="apb_payment_policy_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(
        paymentPolicyPartOne, index
      ) of apbPaymentPolicyText.apbPaymentPolicyTextPartOne"
      :key="`payment_policy_part_one_text_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="paymentPolicyPartOne" />
    </div>
    <div class="j-page-apb__section mt-14 mb-16 text-center">
      <general-table
        :headers="TABLES_ITEMS.paymentsPolicy.headers"
        :items="TABLES_ITEMS.paymentsPolicy.userData"
        disable-adaptivity
      />
    </div>
    <div
      v-for="(
        paymentPolicyPartTwo, index
      ) of apbPaymentPolicyText.apbPaymentPolicyTextPartTwo"
      :key="`payment_policy_part_two_text_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="paymentPolicyPartTwo" />
    </div>
    <div class="j-page-apb__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="apb_blocking_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(blockingText, index) of apbBlockingText"
      :key="`blocking_text_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="blockingText" />
    </div>
    <div class="j-page-apb__section mb-6 ml-3">
      <general-text-list
        :items-list="apbBlockingTextList"
        text-list-items
      />
    </div>
    <div class="j-page-apb__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="apb_bonuses_policy_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(
        bonusesTextPartOne, index
      ) of apbBonusesPolicyText.apbBonusesPolicyTextPartOne"
      :key="`bonuses_text_part_one_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="bonusesTextPartOne" />
    </div>
    <div class="j-page-apb__section mt-12 mb-6 text-center">
      <general-table
        :headers="TABLES_ITEMS.userStatus.headers"
        :items="TABLES_ITEMS.userStatus.itemsList"
        disable-adaptivity
      />
    </div>
    <div
      v-for="(
        bonusesTextPartTwo, index
      ) of apbBonusesPolicyText.apbBonusesPolicyTextPartTwo"
      :key="`bonuses_text_part_two_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="bonusesTextPartTwo" />
    </div>
    <div class="j-page-apb__section mt-12 mb-6 text-center">
      <general-table
        :headers="TABLES_ITEMS.statusDetails.headers"
        :items="TABLES_ITEMS.statusDetails.itemsList"
        disable-adaptivity
      />
    </div>
    <div
      v-for="(
        bonusesTextPartThree, index
      ) of apbBonusesPolicyText.apbBonusesPolicyTextPartThree"
      :key="`bonuses_text_part_three_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="bonusesTextPartThree" />
    </div>
    <div class="j-page-apb__section mb-6 ml-3">
      <general-text-list
        :items-list="apbBonusesPolicyText.apbBonusesPolicyTextListPartThree"
        :column-count="columnCountForMobile"
        text-list-items
      />
    </div>
    <div class="j-page-apb__section mb-6 ml-3">
      <general-text dictionary-key="apb_bonuses_policy_text_part_four" />
    </div>
    <div class="j-page-apb__section mb-6 ml-3">
      <general-text-list
        :items-list="apbBonusesPolicyText.apbBonusesPolicyTextListPartFour"
        :column-count="columnCountForMobile"
        text-list-items
      />
    </div>
    <div
      v-for="(
        bonusesTextPartFive, index
      ) of apbBonusesPolicyText.apbBonusesPolicyTextPartFive"
      :key="`bonuses_text_part_five_${index}`"
      class="j-page-apb__section mb-6"
    >
      <general-text :dictionary-key="bonusesTextPartFive" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { TEXT_TYPES } from '~/constants/general';
import { TABLES_ITEMS } from '~/constants/staticPages';

definePageMeta({ middleware: 'ensure-canonical-available' });
const { t } = useI18n();

usePageMeta(t('account_payouts_bonuses_page_title'), t('account_payouts_bonuses_page_description'));
const apbRegistrationText = computed(() =>
  JSON.parse(t('apb_registration_text')),
);
const apbConfirmationText = computed(() =>
  JSON.parse(t('apb_confirmation_text')),
);
const apbSecurityText = computed(() => JSON.parse(t('apb_security_text')));
const apbPaymentPolicyText = computed(() => {
  const apbPaymentPolicyTextPartOne = JSON.parse(
    t('apb_payment_policy_text_part_one'),
  );
  const apbPaymentPolicyTextPartTwo = JSON.parse(
    t('apb_payment_policy_text_part_two'),
  );

  return {
    apbPaymentPolicyTextPartOne,
    apbPaymentPolicyTextPartTwo,
  };
});
const apbBlockingText = computed(() => JSON.parse(t('apb_blocking_text')));
const apbBlockingTextList = computed(() =>
  JSON.parse(t('apb_blocking_text_list')),
);
const apbBonusesPolicyText = computed(() => {
  const apbBonusesPolicyTextPartOne = JSON.parse(
    t('apb_bonuses_policy_text_part_one'),
  );
  const apbBonusesPolicyTextPartTwo = JSON.parse(
    t('apb_bonuses_policy_text_part_two'),
  );
  const apbBonusesPolicyTextPartThree = JSON.parse(
    t('apb_bonuses_policy_text_part_three'),
  );
  const apbBonusesPolicyTextListPartThree = JSON.parse(
    t('apb_bonuses_policy_text_list_part_three'),
  );
  const apbBonusesPolicyTextListPartFour = JSON.parse(
    t('apb_bonuses_policy_text_list_part_four'),
  );
  const apbBonusesPolicyTextPartFive = JSON.parse(
    t('apb_bonuses_policy_text_part_five'),
  );

  return {
    apbBonusesPolicyTextPartOne,
    apbBonusesPolicyTextPartTwo,
    apbBonusesPolicyTextPartThree,
    apbBonusesPolicyTextListPartThree,
    apbBonusesPolicyTextListPartFour,
    apbBonusesPolicyTextPartFive,
  };
});
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const columnCountForMobile = computed(() => (isMobileMode?.value
  ? '1'
  : '2'));
</script>
